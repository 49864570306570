// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

/* =============================================================================
 *   Global
 * ========================================================================== */

// Based on: http://t3n.de/news/opentype-font-feature-setting-531985/?utm_content=buffer356b0&utm_medium=social&utm_source=twitter.com&utm_campaign=buffer
html{
  font-size: $font_size_px;
  line-height: $line-height;
  -webkit-text-size-adjust: 100%;

  /* Mac OS Bugfix (font showing up way to bold) */
  -webkit-font-smoothing: antialiased;

  /* Ligaturen aktivieren */
  font-feature-settings: "liga", "dlig";

  /* Use OpenType Breaks */
  font-feature-settings:"frac" 1;

  /* Use real small caps */
  font-feature-settings:"smcp" 1;
}

body {
  font-family: $font_first;
  font-weight: $font_weight_normal;

  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  font-kerning: normal;

  /* Silbentrennung */
  hyphens: auto;
}

// Override fonts set by users
p[style*="font-family"],
span[style*="font-family"]{
  font-family: $font_first !important;
}
p[style*="font-family"],
span[style*="font-family"]{
  font-size: 1em !important;
}

/* =============================================================================
 *   Headlines
 * ========================================================================== */
/*
 * Headings
 *
 * Addresses font sizes and margins set differently in IE6/7
 * Addresses font sizes within 'section' and 'article' in FF4+, Chrome, S5
 */

h1,
h2,
h3,
h4 {
  font-family: $font_second;
  color:$title_color;
  font-weight:$font_second_weight_normal;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a{
  color:$title_color;
  text-decoration:none;
}

h1,
.h1 {
  font-size: $h1_font_size;
  line-height:$h1_line_height;
  font-weight:$h1_font_weight;
  margin: 0.67em 0;
  a{
    color:$highlight;
  }
}
h2,
.h2 {
  font-size: $h2_font_size;
  line-height:$h2_line_height;
  font-weight:$h2_font_weight;
  margin: 0.83em 0;
}
h3,
.h3 {
  font-size: $h3_font_size;
  line-height:$h3_line_height;
  font-weight:$h3_font_weight;
  margin: 1em 0;
}
h4,
.h4 {
  font-size: $h4_font_size;
  line-height:$h4_line_height;
  font-weight:$h4_font_weight;
  margin: 1.33em 0;
}
h5,
.h5 {
  font-size: $h5_font_size;
  line-height:$h5_line_height;
  font-weight:$h5_font_weight;
  margin: 1.67em 0;
}
h6,
.h6 {
  font-size: $h6_font_size;
  line-height:$h6_line_height;
  font-weight:$h6_font_weight;
  margin: 2.33em 0;
}

#page-title {
  margin-top: 0;
}

/* =============================================================================
 *   Content Elements
 * ========================================================================== */
p{
  margin:0 0 $paragraph_spacing 0;
  &:empty{
    display:none;
  }
}

pre,
code,
tt,
samp,
kbd,
var {
  font-family: $font_code;
}

b,
strong {
  font-weight: $font_weight_bold;
}

small {
  font-size: $font_small;
}

pre {
  margin: 0 0 1.5em;
}

hr{
  height:0;
  clear:both;
  border-width:1px 0 0 0;
  border-style:solid;
  border-color: $grey;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}


sup {
  top: -0.5em;
}


sub {
  bottom: -0.25em;
}

/* Selection */
::selection {
	background: $selection_color; /* Safari */
  color:$selection_color_foreground;
	}
::-moz-selection {
	background: $selection_color; /* Firefox */
  color:$selection_color_foreground;
}
