/* Image Buttons (completely seperate button style) */
/* Holds helper mixins (extends compass functionality) */
/* Holds reusable objects for global use */
/* =============================================================================
 *   Navigation
 * ========================================================================== */
/* Tiny Menu */
/* =============================================================================
 *   Box Styles
 * ========================================================================== */
/* =============================================================================
 *   Messages
 * ========================================================================== */
/* Flyout */
/* Overlay */
/* Cutline */
/* Section Titles */
/* =============================================================================
 *   Global
 * ========================================================================== */
html {
  font-size: 16px;
  line-height: 1.571em;
  -webkit-text-size-adjust: 100%;
  /* Mac OS Bugfix (font showing up way to bold) */
  -webkit-font-smoothing: antialiased;
  /* Ligaturen aktivieren */
  font-feature-settings: "liga", "dlig";
  /* Use OpenType Breaks */
  font-feature-settings: "frac" 1;
  /* Use real small caps */
  font-feature-settings: "smcp" 1; }

body {
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  font-kerning: normal;
  /* Silbentrennung */
  hyphens: auto; }

p[style*="font-family"],
span[style*="font-family"] {
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif !important; }

p[style*="font-family"],
span[style*="font-family"] {
  font-size: 1em !important; }

/* =============================================================================
 *   Headlines
 * ========================================================================== */
/*
 * Headings
 *
 * Addresses font sizes and margins set differently in IE6/7
 * Addresses font sizes within 'section' and 'article' in FF4+, Chrome, S5
 */
h1,
h2,
h3,
h4 {
  font-family: "Crimson Text", Georgia, serif;
  color: #3f3f3f;
  font-weight: 400; }

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a {
  color: #3f3f3f;
  text-decoration: none; }

h1,
.h1 {
  font-size: 32px;
  line-height: 1.35em;
  font-weight: 400;
  margin: 0.67em 0; }
  h1 a,
  .h1 a {
    color: #0e4dac; }

h2,
.h2 {
  font-size: 26px;
  line-height: 1.1em;
  font-weight: 400;
  margin: 0.83em 0; }

h3,
.h3 {
  font-size: 22px;
  line-height: 1em;
  font-weight: 400;
  margin: 1em 0; }

h4,
.h4 {
  font-size: 16px;
  line-height: 1.35em;
  font-weight: 400;
  margin: 1.33em 0; }

h5,
.h5 {
  font-size: 0.83em;
  line-height: 1.35em;
  font-weight: 600;
  margin: 1.67em 0; }

h6,
.h6 {
  font-size: 0.75em;
  line-height: 1.35em;
  font-weight: 600;
  margin: 2.33em 0; }

#page-title {
  margin-top: 0; }

/* =============================================================================
 *   Content Elements
 * ========================================================================== */
p {
  margin: 0 0 0.9426em 0; }
  p:empty {
    display: none; }

pre,
code,
tt,
samp,
kbd,
var {
  font-family: Consolas, Monaco, "Courier New", Courier, monospace, sans-serif; }

b,
strong {
  font-weight: 600; }

small {
  font-size: 12px; }

pre {
  margin: 0 0 1.5em; }

hr {
  height: 0;
  clear: both;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #959ba4; }

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Selection */
::selection {
  background: #0e4dac;
  /* Safari */
  color: #fff; }

::-moz-selection {
  background: #0e4dac;
  /* Firefox */
  color: #fff; }
